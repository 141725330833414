/* eslint no-console: 0 */

import Vue from 'vue/dist/vue.js'
import BlogFeed from '@components/blog_feed/BlogFeed'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)
Vue.component('blog-feed', BlogFeed)

new Vue({ el: document.getElementById('blog-feed') })
