
import { Model, List } from './base'

class BlogPost extends Model {
  get defaults() {
    return {
      id: null,
      featured_image: '',
      featured_image_alt_text: '',
      blog_post_author: {
        name: ''
      },
      published_url: '',
      name: '',
      meta_description: '',
      topic_ids: []
    }
  }

  get authorName() {
    return this.get('blog_post_author.name').split(',')[0]
  }
}

class BlogPostsList extends List {
  get model() {
    return BlogPost
  }
}

export { BlogPost, BlogPostsList }
