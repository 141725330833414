import axios from 'axios'

const initClient = () => {
  const client = axios.create()

  // Add a request interceptor
  client.interceptors.request.use(
    requestConfig => {
      const csrfMeta = document.querySelector('meta[name=csrf-token]')

      const options = {
        baseURL: window.location.origin,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfMeta.content
        }
      }

      return { ...requestConfig, ...options }
    },
    requestError => {
      // Raven.captureException(requestError)

      return Promise.reject(requestError)
    }
  )

  // Add a response interceptor
  client.interceptors.response.use(
    response => response,
    error => {
      switch (error.response.status) {
        case 401:
          window.location.href = '/users/sign_in'
        case 422:
          break
        default:
          // Raven.captureException(error)
      }

      return Promise.reject(error)
    }
  )

  return client
}

class HTTPClient {
  constructor() {
    this.client = initClient()
  }

  get(url, conf = {}) {
    return this.client.get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  delete(url, conf = {}) {
    return this.client.delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  head(url, conf = {}) {
    return this.client.head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  options(url, conf = {}) {
    return this.client.options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  post(url, data = {}, conf = {}) {
    return this.client.post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  put(url, data = {}, conf = {}) {
    return this.client.put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }

  patch(url, data = {}, conf = {}) {
    return this.client.patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  }
}

export default new HTTPClient()
