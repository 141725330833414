
import { Model, List } from './base'

class BlogTopic extends Model {
  get defaults() {
    return {
      id: null,
      slug: '',
      name: ''
    }
  }

  get url() {
    return `https://blog.railwaymen.org/tag/${this.slug}`
  }
}

class BlogTopicsList extends List {
  get model() {
    return BlogTopic
  }
}

export { BlogTopic, BlogTopicsList }
